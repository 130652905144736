export default {
	display: {
		class: [
			// Display
			"inline",

			// Spacing
			"p-3",

			// Shape
			"rounded-md",

			// Colors
			"text-surface-700 dark:text-white/80",

			// States
			"hover:bg-surface-100 hover:text-surface-700 dark:hover:bg-surface-700/80 dark:hover:text-white/80",

			// Transitions
			"transition",
			"duration-200",

			// Misc
			"cursor-pointer",
		],
	},
};
