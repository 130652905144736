import { useAuthorizedQuery } from "../api/authorized-query";
import { seedgreenAPI } from "../api/axios";
import { Customer } from "../models/Customer";
import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { STALE_TIME_MEDIUM } from "./query.constants";
const CUSTOMER_STALE_TIME = STALE_TIME_MEDIUM;
/**
 * Query for the current logged-in user's customer
 */
export function useCustomer() {
    const query = useQuery({
        enabled: useAuthorizedQuery(),
        queryKey: ["customer"],
        queryFn: async () => {
            const response = await seedgreenAPI.get("/Customer");
            return response.data;
        },
        staleTime: CUSTOMER_STALE_TIME,
        select: (data) => {
            try {
                return new Customer(data);
            }
            catch (error) {
                console.error("query/customer::select ERROR", error);
            }
        },
    });
    return {
        customer: query.data,
        ...query,
    };
}
/**
 * Get all customers - only available for internal users: this query will not be attempted if user doesn't think they have internal access
 * @param user Currently logged in user
 */
export function useCustomers(user) {
    const tokenValid = useAuthorizedQuery();
    const query = useQuery({
        enabled: computed(() => !!(tokenValid.value && user.value?.internalUser)),
        queryKey: ["customers"],
        queryFn: async () => {
            const response = await seedgreenAPI.get("/Customer/All");
            return response.data;
        },
        staleTime: CUSTOMER_STALE_TIME,
        select: (data) => {
            try {
                return data.map((customer) => new Customer(customer));
            }
            catch (error) {
                console.error("query/customers::select ERROR", error);
            }
        },
    });
    return {
        customers: query.data,
        ...query,
    };
}
