<script setup lang="ts">
	import { useAlerts, useClearAlert } from "@/query/alerts";
	import dayjs from "dayjs";
	import Button from "primevue/button";

	const { alerts } = useAlerts();
	const { clearAlert } = useClearAlert();
</script>

<template>
	<div class="flex flex-1 flex-col gap-1">
		<div class="flex justify-between border-b-2 px-4 pb-2">
			<div class="text-xl font-bold">Alerts</div>
			<Button text label="Clear All" size="small" @click="clearAlert(undefined)" v-if="alerts?.length" />
		</div>

		<div class="flex w-full flex-1 flex-col overflow-y-scroll">
			<TransitionGroup name="list" tag="div">
				<div
					v-for="alert in alerts"
					:key="alert.id"
					class="item-center flex justify-between border-b-2 py-4 pl-4"
				>
					<div class="flex flex-1 flex-col">
						<div class="flex items-center gap-2">
							<a
								:href="'PlantingRegion/Detail/' + alert.regionId"
								class="font-semibold text-branding-700"
							>
								<i class="fas fa-leaf" />
								{{ alert.regionName }}
							</a>
						</div>

						<div class="ml-4 pr-10">
							{{ alert.alertText }}
						</div>
					</div>

					<div class="mr-2 flex w-max items-center gap-1">
						<div class="italic">
							{{ dayjs(alert.alertDate).format("ll") }}
						</div>
						<Button icon="pi pi-times" text @click="clearAlert(alert)" />
					</div>
				</div>
			</TransitionGroup>

			<div v-if="alerts?.length === 0" class="flex min-h-20 items-center justify-center italic">No Alerts</div>
		</div>
	</div>
</template>

<style scoped>
	.list-move,
	.list-enter-active,
	.list-leave-active {
		transition: all 0.5s ease;
	}

	.list-enter-from,
	.list-leave-to {
		opacity: 0;
		transform: translateX(30px);
	}

	.list-leave-active {
		position: absolute;
	}
</style>
