import { useAuthorizedQuery } from "../api/authorized-query";
import { seedgreenAPI } from "../api/axios";
import { PresentableUser } from "../models/PresentableUser.js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed } from "vue";
import { STALE_TIME_MEDIUM } from "./query.constants";
const USER_STALE_TIME = STALE_TIME_MEDIUM;
const fetchUser = async () => (await seedgreenAPI.get("User")).data;
const fetchPermissions = async () => (await seedgreenAPI.get("User/Permission")).data;
export function useUser() {
    const query = useQuery({
        enabled: useAuthorizedQuery(),
        queryKey: ["user"],
        queryFn: fetchUser,
        staleTime: USER_STALE_TIME,
        select: (data) => {
            try {
                if (!data)
                    throw new Error("User not found");
                return new PresentableUser(data);
            }
            catch (error) {
                console.error("query/user::select ERROR", error);
            }
        },
    });
    return {
        user: query.data,
        ...query,
    };
}
export function usePermissions() {
    const query = useQuery({
        enabled: useAuthorizedQuery(),
        queryKey: ["permissions"],
        queryFn: fetchPermissions,
        staleTime: USER_STALE_TIME,
    });
    return {
        permissions: query.data,
        permissionsSet: computed(() => new Set(query.data.value ?? [])),
        ...query,
    };
}
/**
 * Update the locale for this user
 */
export function useSetLocale() {
    const queryClient = useQueryClient();
    const mutation = useMutation(setLocaleOptions(queryClient));
    return {
        setLocale: mutation.mutate,
        setLocaleAsync: mutation.mutateAsync,
        ...mutation,
    };
}
export const setLocaleOptions = (queryClient) => {
    function updateLocaleCaches(locale) {
        if (!queryClient.getQueryState(["user"]))
            return;
        // Set locale in user cache
        queryClient.setQueryData(["user"], (old) => ({
            ...old,
            locale,
        }));
    }
    return {
        mutationFn: async (locale) => {
            await seedgreenAPI.put("User/Locale?locale=" + locale);
            return locale;
        },
        onMutate: async (locale) => {
            const context = queryClient.getQueryData(["user"]);
            updateLocaleCaches(locale);
            return context;
        },
        onError: async (error, _variables, context) => {
            console.error("mutation/user/locale::onError", error);
            // Reset optimistic user update
            if (context)
                await queryClient.setQueryData(["user"], context);
            await queryClient.invalidateQueries({ queryKey: ["user"] });
        },
        scope: { id: "user.locale" }, // Run multiple serially
    };
};
