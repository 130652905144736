/**
 * Recursively convert null values to undefined
 * @param obj
 * @returns obj with null values replaced with undefined
 * See: https://stackoverflow.com/q/50374869/23018930
 */
export function nullsToUndefined(obj) {
    if (obj === null || obj === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return undefined;
    }
    if (typeof obj === "object" || Array.isArray(obj)) {
        for (const key in obj) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            obj[key] = nullsToUndefined(obj[key]);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return obj;
}
/**
 * Ingest object from backend API - handles converting null values to undefined as TypeScript expects
 * @param self - Object to receive values
 * @param obj - Source object to ingest
 */
export function ingestAPIObject(self, obj) {
    Object.assign(self, nullsToUndefined(obj));
}
