import axios from "@/lib/seedgreen-api";
import { useQuery } from "@tanstack/vue-query";
import { AxiosError, type AxiosResponse } from "axios";
import * as Sentry from "@sentry/vue";

export function useVerifyToken() {
	const query = useQuery({
		queryKey: ["verify-token"],
		queryFn: async () => {
			try {
				const response = await axios.get("WebApiAuthentication/VerifyToken");
				return handleTokenResponse(response);
			} catch (error) {
				if (error instanceof AxiosError && error.response) {
					return handleTokenResponse(error.response);
				}

				Sentry.captureException(error);
				console.error("Unknown authentication error", error);
				return false;
			}
		},
		staleTime: 1 * 60 * 1000,
		refetchInterval: 5 * 60 * 1000,
	});

	return {
		tokenValid: query.data,
		...query,
	};
}

// Redirects user to login if needed
function handleTokenResponse(response: AxiosResponse) {
	if (response.status === 401 || response.status === 403) {
		const returnExtention = "?ReturnUrl=" + window.location.pathname;
		window.location.href = import.meta.env.VITE_SEEDGREEN_API_BASE_URL + "/Account/Login" + returnExtention;
		return false;
	}

	return true;
}
