export var Permission;
(function (Permission) {
    Permission[Permission["AssignPermissions"] = 1] = "AssignPermissions";
    Permission[Permission["ManageUserAccounts"] = 2] = "ManageUserAccounts";
    Permission[Permission["ViewUserActivity"] = 3] = "ViewUserActivity";
    Permission[Permission["ViewHarvestScorecards"] = 4] = "ViewHarvestScorecards";
    Permission[Permission["ViewWeedingSchedules"] = 5] = "ViewWeedingSchedules";
    Permission[Permission["SetWeedingSchedules"] = 6] = "SetWeedingSchedules";
    Permission[Permission["ViewThinningSchedules"] = 7] = "ViewThinningSchedules";
    Permission[Permission["SetThinningSchedules"] = 8] = "SetThinningSchedules";
    Permission[Permission["ViewFleecingSchedules"] = 9] = "ViewFleecingSchedules";
    Permission[Permission["SetFleecingSchedules"] = 10] = "SetFleecingSchedules";
    Permission[Permission["EditPlantingPurpose"] = 11] = "EditPlantingPurpose";
    Permission[Permission["EditAnyFieldNote"] = 12] = "EditAnyFieldNote";
    Permission[Permission["CreateFieldNotes"] = 13] = "CreateFieldNotes";
    Permission[Permission["CreateRoles"] = 14] = "CreateRoles";
    Permission[Permission["SetIrrigationSchedules"] = 15] = "SetIrrigationSchedules";
    Permission[Permission["ViewIrrigationSchedules"] = 16] = "ViewIrrigationSchedules";
    Permission[Permission["ViewMobileHarvest"] = 17] = "ViewMobileHarvest";
    Permission[Permission["ViewMobileLots"] = 18] = "ViewMobileLots";
    Permission[Permission["ViewPlantingSchedules"] = 19] = "ViewPlantingSchedules";
    Permission[Permission["SetPlantingSchedules"] = 20] = "SetPlantingSchedules";
    Permission[Permission["ViewMobileHarvestOverview"] = 21] = "ViewMobileHarvestOverview";
    Permission[Permission["ManageInternalUserAccounts"] = 22] = "ManageInternalUserAccounts";
    Permission[Permission["RespondToTaskRequest"] = 23] = "RespondToTaskRequest";
    Permission[Permission["CreateTaskRequest"] = 24] = "CreateTaskRequest";
    Permission[Permission["UpdateAnyTask"] = 25] = "UpdateAnyTask";
    Permission[Permission["CreateTask"] = 26] = "CreateTask";
    Permission[Permission["DeleteTask"] = 27] = "DeleteTask";
    Permission[Permission["ViewPlantingTicketHistory"] = 28] = "ViewPlantingTicketHistory";
    Permission[Permission["EditPastPlantingTickets"] = 29] = "EditPastPlantingTickets";
    Permission[Permission["ViewSoilSampleSchedules"] = 30] = "ViewSoilSampleSchedules";
    Permission[Permission["EditSoilSampleSchedules"] = 31] = "EditSoilSampleSchedules";
    Permission[Permission["ViewGeneralTasks"] = 32] = "ViewGeneralTasks";
    Permission[Permission["EditGeneralTasks"] = 33] = "EditGeneralTasks";
    Permission[Permission["ViewChemicalSchedules"] = 34] = "ViewChemicalSchedules";
    Permission[Permission["EditChemicalSchedules"] = 35] = "EditChemicalSchedules";
})(Permission || (Permission = {}));
