import { ingestAPIObject } from "../utils/api";
export class Customer {
    id;
    name;
    logoFilename;
    globalMapCenter;
    harvestStartDate;
    startDayOfWeek;
    //startDateOfWeek?: Date;
    //yearWeekType: ...
    autoUpdateIrrigationRecommendations;
    //irrigationBlockNameType: ...
    constructor(jsonData) {
        ingestAPIObject(this, jsonData);
        this.harvestStartDate = jsonData.harvestStartDate?.slice(0, 10);
    }
}
