import { useVerifyToken } from "../query/verify-token";
import { computed } from "vue";
/**
 * Pass this to the 'enabled' option of the useQuery hook to require a valid token for the query to run.
 *
 * This is used to keep an unauthorized client from sending many unauthorized requests to the server in
 * parallel, instead, this will fail first and redirect them to login. The `VerifyToken` query handles
 * caching and periodically re-checking the authorized state in the background.
 */
export function useAuthorizedQuery() {
    const { tokenValid } = useVerifyToken();
    return computed(() => tokenValid.value ?? false);
}
