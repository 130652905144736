import { useAuthorizedQuery } from "@/lib/authorized-query";
import axios from "@/lib/seedgreen-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import _ from "lodash";
import { PlantingRegionAlert } from "seedgreen-shared/models/PlantingRegionAlert.js";
import * as Sentry from "@sentry/vue";

const ALERTS_STALE_TIME = 10 * 60 * 1000;

const fetchAlerts = async () => (await axios.get("Alerts")).data as object[];

export function useAlerts() {
	const query = useQuery({
		enabled: useAuthorizedQuery(),
		queryKey: ["alerts"],
		queryFn: fetchAlerts,
		staleTime: ALERTS_STALE_TIME,
		select: (data) => {
			try {
				return data.map((t) => new PlantingRegionAlert(t));
			} catch (error) {
				Sentry.captureException(error);
				console.error("query/ranch::select ERROR", error);
			}
		},
	});

	return {
		alerts: query.data,
		...query,
	};
}

/**
 * Clear an alert, or all alerts if nothing passed in
 */
export function useClearAlert() {
	const queryClient = useQueryClient();

	const { mutate, mutateAsync, isPending, isError, error, isSuccess } = useMutation({
		mutationFn: async (alert?: PlantingRegionAlert) => {
			if (!alert) await axios.delete("Alerts");
			else await axios.delete(`/Alerts/${alert.id}`);
			return true;
		},
		onMutate: async (alert?: PlantingRegionAlert) => {
			await queryClient.cancelQueries({ queryKey: ["alerts"] });

			const context = queryClient.getQueryData(["alerts"]);

			updateCaches(alert);

			return context;
		},
		onError: async (error, _variables, context) => {
			console.error("query/alerts/delete::onError", error);

			if (context) await queryClient.setQueryData(["alerts"], context);

			queryClient.invalidateQueries({ queryKey: ["alerts"] });
		},
	});

	function updateCaches(alert?: PlantingRegionAlert) {
		if (!queryClient.getQueryState(["alerts"])) return;

		if (!alert) {
			queryClient.setQueryData(["alerts"], []);
		} else {
			queryClient.setQueryData(["alerts"], (old: Partial<PlantingRegionAlert>[]) =>
				old.filter((a) => a.id !== alert.id),
			);
		}
	}

	return {
		clearAlert: mutate,
		clearAlertAsync: mutateAsync,
		isPending,
		isError,
		error,
		isSuccess,
	};
}
