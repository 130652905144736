import { ingestAPIObject } from "../utils/api";
export class PlantingRegionAlert {
    id;
    regionId;
    regionName;
    alertText;
    alertDate;
    alertType;
    alertCreationDate;
    constructor(jsonData) {
        ingestAPIObject(this, jsonData);
    }
}
