import { ingestAPIObject } from "../utils/api";
export class PresentableUser {
    id;
    name;
    userName;
    customerId;
    internalUser;
    locale;
    canViewScheduling;
    constructor(jsonData) {
        ingestAPIObject(this, jsonData);
    }
}
