import axios from "axios";
export const SEEDGREEN_BASE_URL = 
// Test config - we don't set this as a separate domain, because otherwise it will
// fire off OPTIONS calls which confuses msw
(process.env.NODE_ENV === "test" && "seedgreen-api") ||
    // Vite dotenv expansion:
    // @ts-expect-error import.meta potentially fulfilled by peer dependency
    (import.meta.env?.VITE_SEEDGREEN_API_BASE_URL ??
        // Vue CLI dotenv expansion:
        process.env.VUE_APP_SEEDGREEN_API_BASE_URL ??
        process.env.SEEDGREEN_BASE_URL);
console.assert(SEEDGREEN_BASE_URL, "Axios API Base URL environment variable not set");
/**
 * Seedgreen API with Cookie-based authentication
 */
export const seedgreenAPI = axios.create({
    baseURL: SEEDGREEN_BASE_URL + "/API",
    withCredentials: true,
});
